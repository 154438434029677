<template>
  <section class="absolute w-screen h-screen text-left ">
    <a class="navicon cursor-pointer"
       :class="{navicon__active : open}"
    @click="openMenu"
    ></a>
    <div class="toggle"

    :class="{toggle__active : open}"

    >
      <h1>Toggle Menu</h1>
      <ul class="toggle__menu">
        <li><a href="#">Home</a></li>
        <li><a href="#">About</a></li>
        <li><a href="#">Contacts</a></li>
        <li><a href="#"><span>..and more</span></a></li>
      </ul>
      <ul class="social">
        <li><a><i class="fa fa-github"></i></a></li>
        <li><a><i class="fa fa-twitter"></i></a></li>
        <li> <a><i class="fa fa-facebook"></i></a></li>
        <li><a> <i class="fa fa-vk"></i></a></li>
      </ul>
    </div>
  </section>

</template>

<script>
import jQuery from "jquery";

const $ = jQuery;
window.$ = $;
$('.navicon').on('click', function (e) {
  e.preventDefault();
  $(this).toggleClass('navicon--active');
  $('.toggle').toggleClass('toggle--active');
});

export default {
  name: "HamburgerMenuNr7",


  data() {

    return {

      open: false,

    }


  },

  methods: {

    openMenu() {

      this.open = !this.open


    }
  },


}
</script>


<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Raleway:400,900,700,500,300);

section {
  background-image: url('https://static.pexels.com/photos/7026/fre-sonneveld.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  font-family: 'Raleway', sans-serif;
}

h1 {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 40px;
  text-align: center;
}
h3 {
  text-align: center;
  font-weight: 900;
  font-size: 15px;
  //text-transform: uppercase;
  margin: 15px;
}
small {
  font-size: 12px;
  text-transform: lowercase;
  font-weight: 300;
  text-align: center;
  display: block;
}
.navicon {
  width: 100%;
  background: transparent;
  margin: 80px auto 40px;
  position: relative;
  height: 30px;
  width: 50px;
  display: block;
  z-index: 99;
  transition: linear 0.5s;
  &:before,
  &:after {
    background: #fff;
    backface-visibility: hidden;
    content: "";
    height: 3px;
    left: 0;
    transition: 0.8s ease;
    width: 45px;
  }
  &:before {
    box-shadow: #fff 0 14px 0 0;
    position: absolute;
    top: 0;
  }
  &:after {
    position: absolute;
    top: 28px;
  }
  &__active {
    margin-top: 20px;
    transition: linear 0.5s;
    &:before {
      box-shadow: transparent 0 0 0 0;
      top: 15px;
      transform: rotate(225deg);
    }
    &:after {
      top: 15px;
      transform: rotate(315deg);
    }
  }
}


.toggle {
  display: block;
  margin: 20px auto;
  width: 30%;
  background-color: rgba(255, 255, 255, .8);
  padding: 15px;
  opacity: 0;
  transition: ease-in 0.5s;
  transform: translateY(-200%);
  min-width: 320px;
  &__active {
    display: block;
    opacity: 1;
    transition: ease-in 0.5s;
    transform: translateY(0);
  }
  &__menu {
    margin-bottom: 25px;
    li {
      width: 25%;
      display: block;
      margin: 10px auto;
      a {
        text-decoration: none;
        color: #000;
        display: block;
        text-align: center;
        font-size: 17px;
        text-transform: uppercase;
        border-bottom: 2px solid transparent;
        transition: linear 0.5s;
        font-weight: 500;
        padding: 5px 0;
        span {
          text-transform: lowercase;
        }
        &:hover {
          color: #db5523;
          border-bottom: 2px solid #db5523;
          transition: linear 0.5s ;
          transform: scale(1.15);
          font-weight: 700;
        }
      }
    }
  }
}

.social {
  display: block;
  width: 70%;
  margin: 25px auto;
  text-align: center;
  font-size: 0;
  li {
    display: inline-block;
    width: 25%;
    text-align: center;
    a {
      text-align: center;
      color: #000;
      font-size: 25px;
      transition: linear 0.5s ;
      &:hover {
        color: #db5523;
        transition: linear 0.5s ;
      }
    }
  }
}

</style>